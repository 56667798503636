import { getModuleContentByModuleIdThunk } from '@store/bundle-slice/_thunks'
import { useDispatch } from 'react-redux'
import { isDefined } from '@utils/connascence'

export default function useFetchModuleContents(bundleId: string, channelId: string) {
  const dispatch = useDispatch()

  const fetchModuleData = (moduleId: string) => {
    if (isDefined(channelId)) {
      dispatch(getModuleContentByModuleIdThunk({ moduleId, bundleId, channelId }))
    }
  }

  return { fetchModuleData }
}
